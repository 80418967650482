<template>
  <div id="paginaVendite">
    <!-- Elementi Pagina----------------------------------------------------------------------------------- -->
    <b-row class="match-height">
      <!-- Card Grid View: GridElencoVendite -->
      <b-col xl="12" md="12">
        <b-card tag="article" class="mb-2">
          <b-card-header class="align-items-baseline">
            <div>
              <b-card-title>Elenco Vendite</b-card-title>
              <b-card-text class="mb-0"> Centro Componenti TV </b-card-text>
            </div>
            <!-- dropdown menù laterale dx -->
            <b-dropdown
              variant="link"
              no-caret
              right
              class="chart-dropdown"
              toggle-class="p-0"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-item href="#"> ... </b-dropdown-item>
            </b-dropdown>
          </b-card-header>
          <b-card-body class="mb-0">
            <!-- card body -->
            <b-row>
              <!-- Timer Alert -->
              <b-col xl="6" md="12">
                <b-alert
                  :show="alertADismissCountDown"
                  dismissible
                  variant="warning"
                  @dismissed="alertADismissCountDown = 0"
                  @dismiss-count-down="alertACountDownChanged"
                >
                  <p>Occorre selezionare una riga della tabella</p>
                  <b-progress
                    variant="warning"
                    :max="alertADismissSecs"
                    :value="alertADismissCountDown"
                    height="4px"
                  />
                </b-alert>
              </b-col>

              <b-col xl="12" md="12" class="pt-1 text-center" v-show="loading">
                <b-spinner label="Loading..."></b-spinner>
              </b-col>
              <b-col xl="12" md="12" class="pt-1">
                <ejs-grid
                  id="gridA"
                  ref="gridA"
                  :dataSource="venditeFullData"
                  :allowSorting="true"
                  :sortSettings="gridA.sortOptions"
                  :toolbarClick="gridAClickHandler"
                  :toolbar="gridA.toolbarOptions"
                  :height="gridA.height"
                  :allowFiltering="true"
                  :filterSettings="gridA.filterOptions"
                  :editSettings="gridA.editOptions"
                  :actionBegin="gridAactionBegin"
                  :actionComplete="gridAactionComplete"
                >
                  <e-columns>
                    <e-column
                      field="id"
                      headerText="ID"
                      :isPrimaryKey="true"
                      width="60"
                    ></e-column>
                    <e-column
                      field="data"
                      headerText="Data"
                      width="150"
                      type="date"
                      :format="settings.formatoData"
                    ></e-column>
                    <e-column
                      field="account"
                      headerText="Account"
                      width="150"
                    ></e-column>
                    <e-column
                      field="cliente"
                      headerText="Cliente"
                      width="200"
                    ></e-column>
                    <e-column
                      field="tipologiaVendita"
                      headerText="Codice Tip. Vendita"
                      width="200"
                      foreignKeyField="id"
                      foreignKeyValue="codice"
                      :dataSource="this.elencoTipologieVenditaList"
                    ></e-column>
                    <e-column
                      field="tipologiaVendita"
                      headerText="Descr. Tip. Vendita"
                      width="300"
                      foreignKeyField="id"
                      foreignKeyValue="descrizione"
                      :dataSource="this.elencoTipologieVenditaList"
                    ></e-column>
                    <e-column
                      field="negozioDichiarato"
                      headerText="Negozio D."
                      width="200"
                      foreignKeyField="id"
                      foreignKeyValue="codiceNegozio"
                      :dataSource="this.elencoNegoziList"
                    ></e-column>
                    <e-column
                      field="presidioDichiarato"
                      headerText="Presidio D."
                      width="200"
                      foreignKeyField="id"
                      foreignKeyValue="codicePresidio"
                      :dataSource="this.elencoPresidiList"
                    ></e-column>
                    <e-column
                      field="venditoreDichiarato"
                      headerText="Venditore D."
                      width="200"
                      foreignKeyField="id"
                      foreignKeyValue="cognome"
                      :dataSource="this.elencoVenditoriList"
                    ></e-column>
                    <e-column
                      field="statoContrattoGlobale"
                      headerText="Stato Contratto Globale"
                      width="250"
                      foreignKeyField="val"
                      foreignKeyValue="label"
                      :dataSource="this.statiContrattoList"
                    ></e-column>
                    <e-column
                      field="statoContrattoTv"
                      headerText="Stato Contratto TV"
                      width="200"
                      foreignKeyField="val"
                      foreignKeyValue="label"
                      :dataSource="this.statiContrattoList"
                    ></e-column>
                    <e-column
                      field="statoContrattoTelco"
                      headerText="Stato Contratto Telco"
                      width="200"
                      foreignKeyField="val"
                      foreignKeyValue="label"
                      :dataSource="this.statiContrattoList"
                    ></e-column>
                    <e-column
                      field="note"
                      headerText="Note"
                      width="400"
                    ></e-column>
                    <e-column
                      field="scenarioVendita"
                      headerText="Cod. Scenario V."
                      width="180"
                      foreignKeyField="id"
                      foreignKeyValue="codice"
                      :dataSource="this.elencoScenariVenditaList"
                    ></e-column>
                    <e-column
                      field="scenarioVendita"
                      headerText="Descr. Scenario Vendita"
                      width="400"
                      foreignKeyField="id"
                      foreignKeyValue="descrizione"
                      :dataSource="this.elencoScenariVenditaList"
                    ></e-column>
                  </e-columns>
                </ejs-grid>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- Button:  -->
      <b-col
                  xl="12"
                  md="12"
                  class="mt-1"
                >
                  <ejs-button id="estraiDati" @click.native="preparazioneVendite()"
                    >Estrai i Dati
                  </ejs-button>
                </b-col>
    </b-row>

    <!-- dialogA: dialogDettaglioVendita ---------------------------------------------------------------------------------------------------- -->
    <ejs-dialog
      id="dialogA"
      ref="dialogA"
      :header="dialogA.header"
      :buttons="dialogA.buttons"
      :visible="dialogA.visible"
      :showCloseIcon="dialogA.showCloseIcon"
      :close="dialogAClose"
      :open="dialogAOpen"
      :zIndex="dialogA.zIndex"
      :animationSettings="dialogA.animationSettings"
      :isModal="dialogA.isModal"
      v-on:submit.prevent="noop"
    >
      <!-- Content dialog -->
      <b-tabs ref="tabAdialogA" v-model="dialogA.tabAdialogAIndex">
        <b-tab title="Informazioni vendita" @click="tabClicked('dialogATab01')">
          <b-row class="match-height">
            <!-- Card con Elementi Input: anagrafica -->
            <b-col xl="12" md="12">
              <b-card tag="article" class="mb-2">
                <b-card-header class="align-items-baseline">
                  <div>
                    <b-card-title>Dati Vendita</b-card-title>
                    <b-card-text class="mb-0" />
                  </div>
                </b-card-header>
                <b-card-body class="mb-0">
                  <!-- card body -->
                  <b-row>
                    <!-- Input di tipo testo: venditeActiveData.id  -->
                    <b-col xl="12" md="12" class="mb-2">
                      <label for="id" class="h5">Id</label>
                      <ejs-textbox
                        id="id"
                        v-model="venditeActiveData.id"
                        floatLabelType="Never"
                        cssClass=""
                        placeholder="id"
                        showClearButton="true"
                        type="text"
                        autocomplete="false"
                        :readonly="true"
                      />
                    </b-col>

                    <!-- Input di tipo data: venditeActiveData.data  -->
                    <b-col xl="12" md="12" class="mb-2">
                      <label for="data" class="h5">Data</label>
                      <ejs-datepicker
                        id="data"
                        v-model="venditeActiveData.data"
                        :format="settings.formatoDatepicker"
                        placeholder="Data"
                        :firstDayOfWeek="settings.firstDayOfWeek"
                      />
                    </b-col>

                    <!-- Input di tipo dropdown senza azioni con template: venditeActiveData.scenarioVendita  -->
                    <b-col xl="12" md="12" class="mb-2">
                      <label for="scenarioVendita" class="h5"
                        >Scenario Vendita</label
                      >
                      <ejs-dropdownlist
                        id="scenarioVendita"
                        v-model="venditeActiveData.scenarioVendita"
                        :dataSource="elencoScenariVenditaList"
                        :fields="elencoScenariVenditaFields"
                        placeholder="Selezione Scenario Vendita"
                        :itemTemplate="scenarioVenditaITempl"
                        :valueTemplate="scenarioVenditaVTempl"
                        :change="onScenarioVenditaChange"
                        sortOrder="Ascending"
                        allowFiltering="true"
                      />
                    </b-col>

                    <!-- Input di tipo dropdown senza azioni con template: venditeActiveData.tipologiaVendita  -->
                    <b-col xl="12" md="12" class="mb-2">
                      <label for="tipologiaVendita" class="h5"
                        >Tipologia Vendita</label
                      >
                      <ejs-dropdownlist
                        id="tipologiaVendita"
                        v-model="venditeActiveData.tipologiaVendita"
                        :dataSource="elencoTipologieVenditaList"
                        :fields="elencoTipologieVenditaFields"
                        placeholder="Selezione Tipologia Vendita"
                        :itemTemplate="scenarioVenditaITempl"
                        :valueTemplate="scenarioVenditaVTempl"
                        :change="onTipologiaVenditaChange"
                        sortOrder="Ascending"
                        allowFiltering="true"
                      />
                      <!-- itemTemplate e valueTemplate sono le stesse di scenarioVendita -->
                    </b-col>

                    <!-- Input di tipo testo: venditeActiveData.account  -->
                    <b-col xl="12" md="12" class="mb-2">
                      <label for="account" class="h5">Account</label>
                      <ejs-textbox
                        id="account"
                        v-model="venditeActiveData.account"
                        floatLabelType="Never"
                        cssClass=""
                        placeholder="Account"
                        showClearButton="true"
                        type="text"
                        autocomplete="false"
                      />
                    </b-col>

                    <!-- Input di tipo testo: venditeActiveData.cliente  -->
                    <b-col xl="12" md="12" class="mb-2">
                      <label for="cliente" class="h5">Cliente</label>
                      <ejs-textbox
                        id="cliente"
                        v-model="venditeActiveData.cliente"
                        floatLabelType="Never"
                        cssClass=""
                        placeholder="Cliente"
                        showClearButton="true"
                        type="text"
                        autocomplete="false"
                      />
                    </b-col>

                    <!-- Input di tipo dropdown senza azioni senza template: venditeActiveData.negozioDichiarato  -->
                    <b-col
                      xl="12"
                      md="12"
                      class="mb-2"
                      v-show="showNegozioDichiarato"
                    >
                      <label for="negozioDichiarato" class="h5"
                        >Negozio Dichiarato</label
                      >
                      <ejs-dropdownlist
                        id="negozioDichiarato"
                        v-model="venditeActiveData.negozioDichiarato"
                        :dataSource="elencoNegoziList"
                        :fields="elencoNegoziFields"
                        placeholder="Selezione Negozio Dichiarato"
                        showClearButton="true"
                        sortOrder="Ascending"
                        allowFiltering="true"
                      />
                    </b-col>

                    <!-- Input di tipo dropdown senza azioni senza template: venditeActiveData.negozioReale  -->
                    <b-col
                      xl="12"
                      md="12"
                      class="mb-2"
                      v-show="showNegozioReale"
                    >
                      <label for="negozioReale" class="h5"
                        >Negozio Reale</label
                      >
                      <ejs-dropdownlist
                        id="negozioReale"
                        v-model="venditeActiveData.negozioReale"
                        :dataSource="elencoNegoziList"
                        :fields="elencoNegoziFields"
                        placeholder="Selezione Negozio Reale"
                        showClearButton="true"
                        sortOrder="Ascending"
                        allowFiltering="true"
                      />
                    </b-col>

                    <!-- Input di tipo dropdown senza azioni senza template: venditeActiveData.presidioDichiarato  -->
                    <b-col
                      xl="12"
                      md="12"
                      class="mb-2"
                      v-show="showPresidioDichiarato"
                    >
                      <label for="presidioDichiarato" class="h5"
                        >Presidio Dichiarato</label
                      >
                      <ejs-dropdownlist
                        id="presidioDichiarato"
                        v-model="venditeActiveData.presidioDichiarato"
                        :dataSource="elencoPresidiList"
                        :fields="elencoPresidiFields"
                        placeholder="Selezione Presidio Dichiarato"
                        showClearButton="true"
                        sortOrder="Ascending"
                        allowFiltering="true"
                      />
                    </b-col>

                    <!-- Input di tipo dropdown senza azioni senza template: venditeActiveData.presidioReale  -->
                    <b-col
                      xl="12"
                      md="12"
                      class="mb-2"
                      v-show="showPresidioReale"
                    >
                      <label for="presidioReale" class="h5"
                        >Presidio Reale</label
                      >
                      <ejs-dropdownlist
                        id="presidioReale"
                        v-model="venditeActiveData.presidioReale"
                        :dataSource="elencoPresidiList"
                        :fields="elencoPresidiFields"
                        placeholder="Selezione Presidio Reale"
                        showClearButton="true"
                        sortOrder="Ascending"
                        allowFiltering="true"
                      />
                    </b-col>

                    <!-- Input di tipo dropdown senza azioni senza template: venditeActiveData.venditoreDichiarato  -->
                    <b-col
                      xl="12"
                      md="12"
                      class="mb-2"
                      v-show="showVenditoreDichiarato"
                    >
                      <label for="venditoreDichiarato" class="h5"
                        >Venditore Dichiarato</label
                      >
                      <ejs-dropdownlist
                        id="venditoreDichiarato"
                        v-model="venditeActiveData.venditoreDichiarato"
                        :dataSource="elencoVenditoriList"
                        :fields="elencoVenditoriFields"
                        placeholder="Selezione Venditore Dichiarato"
                        showClearButton="true"
                        sortOrder="Ascending"
                        allowFiltering="true"
                      />
                    </b-col>

                    <!-- Input di tipo dropdown senza azioni senza template: venditeActiveData.venditoreReale  -->
                    <b-col
                      xl="12"
                      md="12"
                      class="mb-2"
                      v-show="showVenditoreReale"
                    >
                      <label for="venditoreReale" class="h5"
                        >Venditore Reale</label
                      >
                      <ejs-dropdownlist
                        id="venditoreReale"
                        v-model="venditeActiveData.venditoreReale"
                        :dataSource="elencoVenditoriList"
                        :fields="elencoVenditoriFields"
                        placeholder="Selezione Venditore Reale"
                        showClearButton="true"
                        sortOrder="Ascending"
                        allowFiltering="true"
                      />
                    </b-col>

                    <!-- Input di tipo dropdown senza azioni senza template: venditeActiveData.agente  -->
                    <b-col
                      xl="12"
                      md="12"
                      class="mb-2"
                      v-show="showAgente"
                    >
                      <label for="agente" class="h5"
                        >Agente Esterno</label
                      >
                      <ejs-dropdownlist
                        id="agente"
                        v-model="venditeActiveData.agente"
                        :dataSource="elencoAgentiList"
                        :fields="elencoAgentiFields"
                        placeholder="Selezione Agente"
                        showClearButton="true"
                        sortOrder="Ascending"
                        allowFiltering="true"
                      />
                    </b-col>

                    <!-- Input di tipo dropdown senza azioni senza template: venditeActiveData.statoContrattoGlobale  -->
                    <b-col
                      xl="12"
                      md="12"
                      class="mb-2"
                      v-show="showStatoContrattoGlobale"
                    >
                      <label for="statoContrattoGlobale" class="h5"
                        >Stato Contratto Globale</label
                      >
                      <ejs-dropdownlist
                        id="statoContrattoGlobale"
                        v-model="venditeActiveData.statoContrattoGlobale"
                        :dataSource="statiContrattoList"
                        :fields="labelValFields"
                        placeholder="Selezione Stato Contratto Globale"
                        showClearButton="true"
                      />
                    </b-col>

                    <!-- Input di tipo dropdown senza azioni senza template: venditeActiveData.statoContrattoTv  -->
                    <b-col
                      xl="12"
                      md="12"
                      class="mb-2"
                      v-show="showStatoContrattoTv"
                    >
                      <label for="statoContrattoTv" class="h5"
                        >Stato Contratto Tv</label
                      >
                      <ejs-dropdownlist
                        id="statoContrattoTv"
                        v-model="venditeActiveData.statoContrattoTv"
                        :dataSource="statiContrattoList"
                        :fields="labelValFields"
                        placeholder="Selezione Stato Contratto Tv"
                        showClearButton="true"
                      />
                    </b-col>

                    <!-- Input di tipo dropdown senza azioni senza template: venditeActiveData.statoContrattoTelco  -->
                    <b-col
                      xl="12"
                      md="12"
                      class="mb-2"
                      v-show="showStatoContrattoTelco"
                    >
                      <label for="statoContrattoTelco" class="h5"
                        >Stato Contratto Telco</label
                      >
                      <ejs-dropdownlist
                        id="statoContrattoTelco"
                        v-model="venditeActiveData.statoContrattoTelco"
                        :dataSource="statiContrattoList"
                        :fields="labelValFields"
                        placeholder="Selezione Stato Contratto Telco"
                        showClearButton="true"
                      />
                    </b-col>

                    <!-- Input di tipo testo multilinea: venditeActiveData.note  -->
                    <b-col xl="12" md="12" class="mb-2">
                      <label for="note" class="h5">Note</label>
                      <ejs-textbox
                        id="note"
                        v-model="venditeActiveData.note"
                        floatLabelType="Never"
                        cssClass=""
                        placeholder="Note"
                        showClearButton="true"
                        type="text"
                        autocomplete="false"
                        :multiline="true"
                        :rows="3"
                      />
                    </b-col>

                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </ejs-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import useJwt from "@/auth/jwt/useJwt";
// import bootstrap-Vue
import {
  LayoutPlugin,
  TabsPlugin,
  CardPlugin,
  DropdownPlugin,
  LinkPlugin,
  AlertPlugin,
  ProgressPlugin,
  ToastPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormCheckboxPlugin,
  FormSelectPlugin,
  FormRatingPlugin,
  SpinnerPlugin,
} from "bootstrap-vue";
// import Syncfusion
import { enableRipple } from "@syncfusion/ej2-base";
import { Query } from "@syncfusion/ej2-data";
// import {
//   extend, addClass, createElement, isNullOrUndefined, detach, EventHandler,
// } from '@syncfusion/ej2-base'
import {
  DateTimePickerPlugin,
  DatePickerPlugin,
  TimePickerPlugin,
  CalendarPlugin,
} from "@syncfusion/ej2-vue-calendars";
import {
  ButtonPlugin,
  CheckBoxPlugin,
  SwitchPlugin,
} from "@syncfusion/ej2-vue-buttons";
import {
  TextBoxPlugin,
  NumericTextBoxPlugin,
  MaskedTextBoxPlugin,
  UploaderPlugin,
} from "@syncfusion/ej2-vue-inputs";
import {
  DropDownListPlugin,
  MultiSelectPlugin,
  ListBoxPlugin,
  CheckBoxSelection,
} from "@syncfusion/ej2-vue-dropdowns";
import { DialogPlugin } from "@syncfusion/ej2-vue-popups";

import {
  GridPlugin,
  ForeignKey,
  Edit as GridEdit,
  Toolbar as GridToolbar,
  Sort as GridSort,
  Filter as GridFilter,
} from "@syncfusion/ej2-vue-grids";
import { DropDownButtonPlugin } from "@syncfusion/ej2-vue-splitbuttons";

const axios = require("axios");
// use bootstrap-vue
Vue.use(TabsPlugin);
Vue.use(CardPlugin);
Vue.use(LayoutPlugin);
Vue.use(DropdownPlugin);
Vue.use(LinkPlugin);
Vue.use(AlertPlugin);
Vue.use(ProgressPlugin);
Vue.use(ToastPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormRatingPlugin);
Vue.use(SpinnerPlugin);
// use Syncfusion
Vue.use(TextBoxPlugin);
Vue.use(NumericTextBoxPlugin);
Vue.use(MaskedTextBoxPlugin);
Vue.use(UploaderPlugin);
Vue.use(DatePickerPlugin);
Vue.use(DateTimePickerPlugin);
Vue.use(TimePickerPlugin);
Vue.use(DropDownListPlugin);
Vue.use(MultiSelectPlugin);
Vue.use(ButtonPlugin);
Vue.use(CheckBoxPlugin);
Vue.use(SwitchPlugin);
Vue.use(DialogPlugin);
Vue.use(GridPlugin);
Vue.use(DropDownButtonPlugin);
Vue.use(ListBoxPlugin);
Vue.use(CalendarPlugin);
enableRipple(true);
// api
const apiVueEndPoint = process.env.VUE_APP_API_ENDPOINT;
const apiVenditeUrl = "/main/api/vendita";
const apiScenariVenditaUrl = "/main/api/scenarioVendita";
const apiTipologieVenditaUrl = "/main/api/tipologiaVendita";
const apiNegoziUrl = "/main/api/negozio";
const apiVenditoriUrl = "/main/api/venditore";
const apiPresidiUrl = "/main/api/presidio";
const apiAgentiUrl = "/main/api/agente";

const apiPreparazioneVenditeCalcoloUrl = "/main/api/preparazioneVendite/calcolo";

// personalizzo la visualizzazione della tendina visualizzata dal dropdown scenarioVendita
var scenarioVenditaITVue = Vue.component("scenarioVenditaITempl", {
  template: `<span><span class='nonDefinita'>{{data.codice}}</span>&nbsp<span class ='nonDefinita'>{{data.descrizione}}</span></span>`,
  data() {
    return {
      data: {},
    };
  },
});

// personalizzo la visualizzazione del valore visualizzato dal dropdown scenarioVendita
var scenarioVenditaVTVue = Vue.component("scenarioVenditaVTempl", {
  template: `<span><span class='nonDefinita'>{{data.codice}}</span>&nbsp<span class ='nonDefinita'>{{data.descrizione}}</span></span>`,
  data() {
    return {
      data: {},
    };
  },
});

export default Vue.extend({
  components: {},
  data() {
    return {
      // spinner
      loading: false,
      // tmp
      nonMostrare: false, // utile per non cancellare codice in editing
      showNegozioDichiarato: false,
      showNegozioReale: false,
      showPresidioDichiarato: false,
      showPresidioReale: false,
      showVenditoreDichiarato: false,
      showVenditoreReale: false,
      showAgente: false,
      showStatoContrattoGlobale: false,
      showStatoContrattoTv: false,
      showStatoContrattoTelco: false,
      // generali
      settings: {
        formatoData: { type: "date", format: "dd-MM-yyyy" }, // da usare per grid
        formatoDatepicker: "dd-MM-yyyy", // da usare per i datepicker
        formatoOra: "HH:mm",
        intervalloOra: 15,
        listboxSelectionSettings: { showCheckbox: true },
      },
      // struttura etichetta - valore per dropdown
      labelValFields: { value: "val", text: "label" },
      // soggetti
      venditeFullData: [], // elenco completo dati Vendite
      venditeActiveId: 0, // Id vendita attivo (0 se nuovo)
      venditeActiveData: {}, // dati completi della vendita attivo
      venditeSavedData: {}, // dati completi della vendita attivo
      venditeDefaultData: {}, // dati completi di default per nuove Vendite

      // per stati Contratto
      statiContrattoList:[
        { label: "Attivo", val: "attivo" },
        { label: "Attesa Matricola", val: "attesaMatricola" },
        { label: "Completo", val: "completo" },
        { label: "Firmato", val: "firmato" },
        { label: "KO Confirmed", val: "KOConfirmed" },
        { label: "Cessato", val: "cessato" },
        { label: "Annullato", val: "annullato" },
      ],

      // per gestione elenco scenari Vendita
      elencoScenariVenditaList: [],
      elencoScenariVenditaFields: { value: "id", text: "codice" },

      // per gestione elenco tipologie Vendita
      elencoTipologieVenditaList: [],
      elencoTipologieVenditaFields: { value: "id", text: "codice" },

      // per gestione elenco Negozi
      elencoNegoziList: [],
      elencoNegoziFields: { value: "id", text: "citta" },

      // per gestione elenco Presidi
      elencoPresidiList: [],
      elencoPresidiFields: { value: "id", text: "nomePresidio" },

      // per gestione elenco Venditori
      elencoVenditoriList: [],
      elencoVenditoriFields: { value: "id", text: "cognome" },

      // per gestione elenco Agenti
      elencoAgentiList: [],
      elencoAgentiFields: { value: "id", text: "cognome" },

      // per alertA
      alertADismissSecs: 3,
      alertADismissCountDown: 0,

      // per dialogA
      dialogA: {
        header: "",
        visible: false,
        showCloseIcon: true,
        buttons: [
          {
            click: this.dialogAOk,
            buttonModel: { content: "OK", isPrimary: true },
          },
          { click: this.dialogACancel, buttonModel: { content: "Cancel" } },
        ],
        animationSettings: { effect: "FadeZoom", duration: 400, delay: 0 }, // { effect: 'None' }, // selezionata per primo livello
        zIndex: 1050,
        tabAdialogAIndex: 0,
        isModal: false,
      },
      // per gridA
      gridAFlag: false,
      gridA: {
        height: 300,
        editOptions: {
          allowEditing: false,
          allowDeleting: true,
        },
        filterOptions: { type: "Excel" },
        toolbarOptions: [
          {
            text: "Nuovo",
            tooltipText: "Inserisci nuova vendita",
            prefixIcon: "e-plus",
            id: "new",
          },
          {
            text: "Apri",
            tooltipText: "Modifica vendita",
            prefixIcon: "e-expand",
            id: "edit",
          },
          {
            text: "Cancella",
            tooltipText: "Cancella vendita",
            prefixIcon: "e-cancel",
            id: "delete",
          },
          "Search",
        ],
        sortOptions: { columns: [{ field: "id", direction: "Ascending" }] },
      },
      // per itemtemplate
      scenarioVenditaITempl: function () {
        return { template: scenarioVenditaITVue };
      },
      scenarioVenditaVTempl: function () {
        return { template: scenarioVenditaVTVue };
      },
    };
  },
  created() {
    console.log("Pagina Vendite Creata");
    this.loading = true;
    console.log(this.loading);
    console.log("Recupero elenchi dal backend");
    // scenariVendita
    useJwt.get(apiScenariVenditaUrl).then((d) => {
      console.log("Elenco Scenari Vendita");
      console.log(d.data);
      this.elencoScenariVenditaList = d.data;
    });
    // tipologieVendita
    useJwt.get(apiTipologieVenditaUrl).then((d) => {
      console.log("Elenco Tipologie Vendita");
      console.log(d.data);
      this.elencoTipologieVenditaList = d.data;
    });
    // negozi
    useJwt.get(apiNegoziUrl).then((d) => {
      console.log("Elenco Negozi");
      console.log(d.data);
      this.elencoNegoziList = d.data;
    });
    // venditori
    useJwt.get(apiVenditoriUrl).then((d) => {
      console.log("Elenco Venditori");
      console.log(d.data);
      this.elencoVenditoriList = d.data;
    });
    // presidi
    useJwt.get(apiPresidiUrl).then((d) => {
      console.log("Elenco Presidi");
      console.log(d.data);
      this.elencoPresidiList = d.data;
    });
    // agenti
    useJwt.get(apiAgentiUrl).then((d) => {
      console.log("Elenco Agenti");
      console.log(d.data);
      this.elencoAgentiList = d.data;
    });
    // venditeFullData
    // recupero elenco Vendite dal backend
    if (localStorage.getItem("userData") !== null) {
      console.log("Recupero elenco Vendite dal backend");
      useJwt.get(apiVenditeUrl).then((d) => {
        console.log("Vendite");
        console.log(d.data);
        this.venditeFullData = d.data;
        this.loading = false;
      });
    } else {
      console.log("Non trovo UserData, prova ancora");
    }

    // Recupero/definisco i valori di default delle entità utilizzate nel componente

    // venditeDefaultData ( esplicitato nei pulsanti "Nuovo.." della gridA )
    this.venditeDefaultData = {};
  },

  provide: {
    // per grid
    grid: [GridToolbar, GridSort, GridFilter, GridEdit, ForeignKey],
    // per listBox
    listbox: [CheckBoxSelection],
    // per multiselect
    multiselect: [CheckBoxSelection],
  },
  methods: {
    // per bottone Test Api Simo
    preparazioneVendite() {

      // chiamata API chart
      const richiesta = { query: "preparazioneVendite", anno: 2022 };
          useJwt.post(apiPreparazioneVenditeCalcoloUrl, richiesta).then((d) => {
            console.log("risposta backend");
            console.log(d.data);
          });



      
    },
    // per dialogA
    dialogAOpen(args) {
      console.log("Apertura dialogA");
      // assegnare le informazioni da visualizzare nel dialog A
      console.log("vendita attiva");
      console.log(this.venditeActiveId);
      console.log(this.venditeActiveData);
      this.venditeSavedData = { ...this.venditeActiveData };
      // evita chiusura dialog su pressione enter
      let dialogObj = args.element.ej2_instances[0];
      dialogObj.keyDown = this.noop.bind(this);
      dialogObj.dataBind();
      // inizializza la visibilità dei campi in base ai dati presenti in ScenariVendita
      if (this.venditeActiveId != 0) {
        // recupero i dati della configurazione di scenario di vendita corrente da elencoScenariVenditaList
        var arrayToSearch = this.elencoScenariVenditaList;
        var itemToFind = this.venditeActiveData.scenarioVendita;
        var scenarioVenditaData = arrayToSearch.find(
          (item) => item.id === itemToFind
        );
        console.log(scenarioVenditaData);
        this.showNegozioDichiarato = scenarioVenditaData.mostraCSSDichiarato;
        this.showNegozioReale = scenarioVenditaData.mostraCSSReale;
        this.showPresidioDichiarato = scenarioVenditaData.mostraUTCDichiarato;
        this.showPresidioReale = scenarioVenditaData.mostraUTCReale;
        this.showVenditoreDichiarato =
          scenarioVenditaData.mostraVenditoreDichiarato;
        this.showVenditoreReale = scenarioVenditaData.mostraVenditoreReale;
        this.showAgente = scenarioVenditaData.mostraAgenteEsterno;
        // recupero i dati della configurazione di tipologie di vendita corrente da elencoTipologieVenditaList
        var arrayToSearch2 = this.elencoTipologieVenditaList;
        var itemToFind2 = this.venditeActiveData.tipologiaVendita;
        var tipologiaVenditaData = arrayToSearch2.find(
          (item) => item.id === itemToFind2
        );
        console.log(tipologiaVenditaData);
        this.showStatoContrattoGlobale =
          tipologiaVenditaData.necessitaStatoContrattoGlobale;
        this.showStatoContrattoTv =
          tipologiaVenditaData.necessitaStatoContrattoTV;
        this.showStatoContrattoTelco =
          tipologiaVenditaData.necessitaStatoContrattoTelco;
      }
    },
    dialogAClose() {
      console.log("Chiusura dialogA");
      this.dialogA.tabAdialogAIndex = 0;
      console.log(this.venditeActiveData);
    },
    dialogAOk() {
      console.log("Cliccato bottone OK di dialogA");
      // salvataggio informazioni in remoto
      if (this.venditeActiveId === 0) {
        // salvataggio in caso di new
        console.log(this.venditeActiveData);
        useJwt
          .post(apiVenditeUrl, this.venditeActiveData)
          .then((res) => {
            //console.log(res.status);
            this.venditeActiveId = res.data.id;
            return this; // restituisci contesto per permettere aggiornamento grid
          })
          .then((context) => {
            useJwt.get(apiVenditeUrl).then((res) => {
              console.log("Aggiornamento gridA con l'aggiunta di una vendita");
              context.venditeFullData = res.data;
              context.venditeFullData = [...context.venditeFullData];
              console.log(res.data);
            });
            // nascondi dialog solo se creazione avvenuta con successo
            context.$refs.dialogA.hide();
          })
          .catch((error) => {
            // costruzione messaggio di errore da mostrare
            const obj = JSON.parse(error.response.request.response);
            const message = Object.keys(obj)[0] + ": " + Object.values(obj)[0];
            this.makeToast("primary", "ERRORE", message);
            console.log(Object.keys(obj)[0]);
            console.log(Object.values(obj)[0]);
          });
      } else {
        // salvataggio in caso di edit
        const editUrl = `${apiVenditeUrl}/${this.venditeActiveId}`;
        // qui devo fare caricamento dati ma non include i file da gestire con upload
        useJwt
          .patch(editUrl, this.venditeActiveData)
          .then((res) => {
            console.log(res.status);
            return this; // restituisci contesto per permettere aggiornamento gridA
          })
          .then((context) => {
            useJwt.get(apiVenditeUrl).then((res) => {
              console.log("Aggiornamento gridA con la modifica di una vendita");
              context.venditeFullData = res.data;
              context.venditeFullData = [...context.venditeFullData];
            });
            // nascondi dialog solo se creazione avvenuta con successo
            context.$refs.dialogA.hide();
          })
          .catch((error) => {
            // costruzione messaggio di errore da mostrare
            // TODO: possibili errori multipli
            const obj = JSON.parse(error.response.request.response);
            const message = Object.keys(obj)[0] + ": " + Object.values(obj)[0];
            this.makeToast("primary", "ERRORE", message);
            //console.log(error.response.data.message || error.message)
            console.log(Object.keys(obj)[0]);
            console.log(Object.values(obj)[0]);
          });
      }
      // triggerare aggiornamento filtering / ordinamento? <----------------------
    },
    dialogACancel() {
      console.log("Cliccato bottone Cancel di dialogA");
      this.$refs.dialogA.hide();
    },
    // per gridA
    gridAClickHandler(args) {
      // pulsante NEW
      if (args.item.id === "new") {
        this.dialogA.header = "Nuova vendita";
        this.venditeDefaultData = {
          id: 0,
          data: null,
          scenarioVendita: null,
          tipologiaVendita: null,
          account: null,
          cliente: null,
          negozioDichiarato: null,
          negozioReale: null,
          presidioDichiarato: null,
          presidioReale: null,
          venditoreDichiarato: null,
          venditoreReale: null,
          agente: null,
          statoContrattoGlobale: null,
          statoContrattoTv: null,
          statoContrattoTelco: null,
        };
        this.venditeActiveData = this.venditeDefaultData;
        console.log("Premuto pulsante creazione nuova vendita da gridA");
        this.venditeActiveId = 0;
        // inizializzo
        this.showNegozioDichiarato = false;
        this.showNegozioReale = false;
        this.showPresidioDichiarato = false;
        this.showPresidioReale = false;
        this.showVenditoreDichiarato = false;
        this.showVenditoreReale = false;
        this.showAgente = false;
        this.showStatoContrattoGlobale = false;
        this.showStatoContrattoTv = false;
        this.showStatoContrattoTelco = false;

        this.$refs.dialogA.show(true);
      }
      // pulsante EDIT
      else if (args.item.id === "edit") {
        console.log("Premuto pulsante modifica vendita da gridA");
        try {
          const selectedRow = this.$refs.gridA.getSelectedRecords();
          let found = false;
          this.venditeActiveId = selectedRow[0].id;
          console.log(this.venditeActiveId);
          for (const i in this.venditeFullData) {
            if (this.venditeFullData[i].id === this.venditeActiveId) {
              this.venditeActiveData = { ...this.venditeFullData[i] };
              found = true;
              break;
            }
          }
          console.log("VENDITA CORRENTE");
          console.log(this.venditeActiveData);
          console.log(this.venditeActiveId);

          if (found) {
            console.log("Edit vendita");
            this.dialogA.header = "vendita";
            this.$refs.dialogA.show(true);
          } else {
            console.log("Elemento non trovato");
          }
        } catch (err) {
          console.log("Occorre selezionare una riga della tabella");
          this.alertAShow();
        }
      }
      // Pulsante DELETE
      else if (args.item.id === "delete") {
        try {
          console.log("Premuto pulsante elimina vendita da gridA");
          const selectedRow = this.$refs.gridA.getSelectedRecords();
          const deletedElementId = selectedRow[0].id;

          const deleteUrl = `${apiVenditeUrl}/${deletedElementId}`;
          this.$refs.gridA.ej2Instances.deleteRecord();

          useJwt.delete(deleteUrl).then((res) => {
            console.log("Eliminazione avvenuta con successo");
          });
        } catch (err) {
          console.log(err);
          console.log("Occorre selezionare una riga della tabella");
          this.alertAShow();
        }
      }
    },
    gridAactionBegin(e) {
      if (!this.gridAFlag) {
        // Add and edit operations
        if (e.requestType === "save") {
          const editedData = e.data;
          console.log(editedData);
          // The default edit operation is cancelled
          e.cancel = true;
          const editUrl = `${apiVenditeUrl}/${editedData.id}`;
          useJwt.patch(editUrl, editedData).then((res) => {
            console.log(res);
            if (res.status === 200) {
              // se tutto OK
              // Flag is enabled to skip this execution when grid ends add/edit
              this.gridAFlag = true;
              // If your data is updated at the server side successfully, we can call the grid endEdit() method to save the edited data to the Grid
              this.$refs.gridA.endEdit();
            } else {
              this.makeToast(
                "primary",
                "Attenzione",
                "Salvataggio vendita non riuscito"
              );
            }
          });
        }
      }
    },
    // Grid’s actionComplete event handler
    // Triggers after an action is completed
    gridAactionComplete(e) {
      if (e.requestType === "save") {
        // The flag is disabled after operation is successfully performed so that it can enter the condition on next execution
        this.gridAFlag = false;
      }
    },
    // per click tabs
    tabClicked(args) {
      switch (args) {
        case "dialogATab01":
          console.log("Sei nel primo tab del dialogA");
          break;
        case "dialogATab02":
          console.log("Sei nel secondo tab del dialogA");
          break;
        default:
          console.log(args);
      }
    },
    // per alert
    alertACountDownChanged(alertADismissCountDown) {
      this.alertADismissCountDown = alertADismissCountDown;
    },
    alertAShow() {
      this.alertADismissCountDown = this.alertADismissSecs;
    },
    // per toast
    makeToast(variant = null, title = "titolo", message = "messaggio") {
      this.$bvToast.toast(message, {
        title,
        variant,
        solid: true,
        autoHideDelay: 10000,
      });
    },
    noop() {
      console.log("premuto enter");
    },
    // per aggiornamento scenario di vendita
    onScenarioVenditaChange: function (e) {
      if (this.venditeActiveData.scenarioVendita) {
        console.log("Scenario di Vendita Corrente:");
        console.log(this.venditeActiveData.scenarioVendita);
        // recupero i dati della configurazione di scenario di vendita corrente da elencoScenariVenditaList
        var arrayToSearch = this.elencoScenariVenditaList;
        var itemToFind = this.venditeActiveData.scenarioVendita;
        var scenarioVenditaData = arrayToSearch.find(
          (item) => item.id === itemToFind
        );
        console.log(scenarioVenditaData);
        this.showNegozioDichiarato = scenarioVenditaData.mostraCSSDichiarato;
        this.showNegozioReale = scenarioVenditaData.mostraCSSReale;
        this.showPresidioDichiarato = scenarioVenditaData.mostraUTCDichiarato;
        this.showPresidioReale = scenarioVenditaData.mostraUTCReale;
        this.showVenditoreDichiarato =
          scenarioVenditaData.mostraVenditoreDichiarato;
        this.showVenditoreReale = scenarioVenditaData.mostraVenditoreReale;
        this.showAgente = scenarioVenditaData.mostraAgenteEsterno;
      }
    },
    // per aggiornamento tipologia di vendita
    onTipologiaVenditaChange: function (e) {
      if (this.venditeActiveData.tipologiaVendita) {
        console.log("Tipologia di Vendita Corrente:");
        console.log(this.venditeActiveData.tipologiaVendita);
        // recupero i dati della configurazione di tipologie di vendita corrente da elencoTipologieVenditaList
        var arrayToSearch2 = this.elencoTipologieVenditaList;
        var itemToFind2 = this.venditeActiveData.tipologiaVendita;
        var tipologiaVenditaData = arrayToSearch2.find(
          (item) => item.id === itemToFind2
        );
        console.log(tipologiaVenditaData);
        this.showStatoContrattoGlobale =
          tipologiaVenditaData.necessitaStatoContrattoGlobale;
        this.showStatoContrattoTv =
          tipologiaVenditaData.necessitaStatoContrattoTV;
        this.showStatoContrattoTelco =
          tipologiaVenditaData.necessitaStatoContrattoTelco;
      }
    },
  },
  computed: {},
});

/* eslint-disable global-require */
</script>

<style lang="scss">
@import "~@syncfusion/ej2-base/styles/material.css";
@import "~@syncfusion/ej2-buttons/styles/material.css";
@import "~@syncfusion/ej2-vue-buttons/styles/material.css";
@import "~@syncfusion/ej2-calendars/styles/material.css";
@import "~@syncfusion/ej2-calendars/styles/datepicker/material.css";
@import "~@syncfusion/ej2-dropdowns/styles/material.css";
@import "~@syncfusion/ej2-vue-popups/styles/material.css";
@import "~@syncfusion/ej2-vue-navigations/styles/material.css";
@import "~@syncfusion/ej2-inputs/styles/material.css";
@import "~@syncfusion/ej2-vue-layouts/styles/material.css";
@import "~@syncfusion/ej2-vue-richtexteditor/styles/material.css";
@import "~@syncfusion/ej2-splitbuttons/styles/material.css";
@import "~@syncfusion/ej2-grids/styles/material.css";
@import "~@syncfusion/ej2-vue-spreadsheet/styles/material.css";
@import "~@syncfusion/ej2-schedule/styles/material.css";
@import "~@syncfusion/ej2-vue-documenteditor/styles/material.css";
@import "~@syncfusion/ej2-treegrid/styles/material.css";
@import "~@syncfusion/ej2-vue-gantt/styles/material.css";
@import "~@syncfusion/ej2-vue-diagrams/styles/material.css";
@import "~@syncfusion/ej2-vue-kanban/styles/bootstrap.css";

.e-btn-icon.e-open-icon {
  background: url("~@/assets/images/svg/open2-svgrepo-com.svg");
  height: 18px;
  width: 18px;
}

.e-btn-icon.e-delete-icon {
  background: url("~@/assets/images/svg/garbage-svgrepo-com.svg");
  height: 18px;
  width: 18px;
}

.e-btn-icon.e-download-icon {
  background: url("~@/assets/images/svg/download2-svgrepo-com.svg");
  height: 18px;
  width: 18px;
}

.e-btn-icon.e-search-icon {
  background: url("~@/assets/images/svg/search-svgrepo-com.svg");
  height: 18px;
  width: 18px;
}

.e-btn-icon.e-upload-icon {
  background: url("~@/assets/images/svg/upload-svgrepo-com.svg");
  height: 18px;
  width: 18px;
}

.e-btn-icon.e-add-icon {
  background: url("~@/assets/images/svg/add2-svgrepo-com.svg");
  height: 18px;
  width: 18px;
}
</style>
